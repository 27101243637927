import {useEffect, useState} from 'react';
import cn from 'classnames';
import type {VariantProps} from 'class-variance-authority';

import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import BlogSection from '@/components/pages/blog/BlogSection/BlogSection';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import type {
  Article,
  GroupedArticlesListProps,
  BlogSectionProps,
} from '@/components/blog/types';
import type {Mode} from '@/components/base/types';
import {useMediaQuery} from '@/hooks/useMediaQuery';

import {
  articleCarouselDotsItem,
  articleCarouselDotsWrapper,
  articleCarouselItemStyles,
  articleCarouselStyles,
} from './style';

interface ArticleCarouselProps
  extends GroupedArticlesListProps,
    VariantProps<typeof articleCarouselStyles>,
    BlogSectionProps {
  carouselName: string;
  preferredBlogCardTag?: string;
  blogCardMode?: Mode;
  blogPageType?: 'article' | 'frontPage';
}

export default function ArticleCarousel({
  primaryArticles,
  enBlogPath,
  heading,
  headingTag,
  link,
  carouselName,
  className,
  mode = 'blogBP',
  preferredBlogCardTag,
  blogType,
  blogCardMode,
  blogPageType = 'frontPage',
}: ArticleCarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const isMobile = useMediaQuery('(max-width: 1119px)');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            setActiveIndex(
              [...entry.target.parentNode!.children].indexOf(entry.target),
            );
          }
        });
      },
      {threshold: 0.7},
    );

    document.querySelectorAll(`.${carouselName}`).forEach((card) => {
      observer.observe(card);
    });
  }, [carouselName]);

  const modifiedPrimaryArticlesImages = primaryArticles.map((article) => {
    if (!article.imageUrl) {
      return article;
    }

    const newImageUrl = new URL(article?.imageUrl || '');
    newImageUrl.searchParams.set('width', '592');

    return {
      ...article,
      imageUrl: newImageUrl.toString(),
    };
  });

  const blogCardType =
    isMobile || blogPageType === 'frontPage' ? 'grid-4' : 'noImage';

  return (
    <BlogSection
      heading={heading}
      link={link}
      containerStyles={
        mode === 'default' ? 'max-lg:!mr-0' : 'max-desktop:!mr-0'
      }
      className={className}
      headingTag={headingTag}
    >
      <div className={articleCarouselStyles({mode})}>
        {modifiedPrimaryArticlesImages.map((article: Article) => (
          <div
            key={`card-${article.handle}`}
            className={cn(carouselName, articleCarouselItemStyles({mode}))}
          >
            <BlogCard
              blogType={blogType}
              article={article}
              enBlogPath={enBlogPath}
              type={blogCardType}
              mode={blogCardMode}
              preferredTag={
                isEnterpriseBlog ? article.topicHandle : preferredBlogCardTag
              }
            />
          </div>
        ))}
      </div>
      <div className={articleCarouselDotsWrapper({mode})}>
        {modifiedPrimaryArticlesImages.map((_, index) => (
          <div
            key={`indicator-${index}`}
            className={articleCarouselDotsItem({
              isActive: activeIndex === index,
              blogType,
            })}
          />
        ))}
      </div>
    </BlogSection>
  );
}
